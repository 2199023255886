import { useEffect, useState } from 'react';

const { screens } = require('@nrdy-marketing-engine/theming/src/screens');
const FIXED_NAVBAR_MIN_HEIGHT = 676;
const FIXED_NAVBAR_MAX_WIDTH = parseInt(screens.xl);

const useFixedNavbar = () => {
  const [isFixed, setIsFixed] = useState(true);

  useEffect(() => {
    const setFixedValue = () => {
      const heightInFixedRange = window.innerHeight >= FIXED_NAVBAR_MIN_HEIGHT;
      const widthInFixedRange = window.innerWidth < FIXED_NAVBAR_MAX_WIDTH;

      setIsFixed(heightInFixedRange || widthInFixedRange);
    };
    const onWindowResize = () => setFixedValue();

    // initialize
    setFixedValue();

    window.addEventListener('resize', onWindowResize, { passive: true });

    // cleanup listener
    return () => window.removeEventListener('resize', onWindowResize);
  }, []);

  return isFixed;
};

export default useFixedNavbar;
