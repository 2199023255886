/**
 * A class which provides a simple pub/sub eventing system. This can be exposed using
 * createContext in order to allow components to receive events from components underneath.
 *
 *     import { EventEmitter, EventEmitterContext } from '@nrdy-marketing-engine/eventing';
 *
 *     function Container() {
 *       const [emitter] = React.useState(new EventEmitter);
 *
 *       React.useEffect(() => {
 *         const subscription = emitter.subscribe('EVENT', () => {
 *           console.debug('EVENT was fired.');
 *         });
 *
 *         return () => subscription.unsubscribe();
 *       }, [emitter]);
 *
 *       return (
 *         <EventEmitterContext.Provider value={emitter}>
 *           { children }
 *         </EventEmitterContext.Provider>
 *       );
 *     }
 *
 *     function ChildComponent() {
 *       const emitter = React.useContext(EventEmitterContext);
 *       return (
 *         <button onClick={() => emitter.emit('EVENT')}>Emit!</button>
 *       );
 *     }
 */
export class EventEmitter {
  events: Record<string, ((data: any) => void)[]>;

  constructor() {
    this.events = {};
  }

  subscribe<T>(name: string, cb: (data: T) => void) {
    (this.events[name] || (this.events[name] = [])).push(cb);

    return {
      unsubscribe: () => {
        this.events[name] && this.events[name].splice(this.events[name].indexOf(cb), 1);
      },
    };
  }

  emit(name: string, data: Record<string, any> = {}) {
    (this.events[name] || []).forEach((fn) => fn(data));
  }
}

export default EventEmitter;
