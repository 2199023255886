import { PanelData, PanelStateProps } from '@nrdy-marketing-engine/form-wizard/@types';
import React from 'react';

const PanelContext = React.createContext<PanelData & PanelStateProps>({
  _modelApiKey: 'panel_content',
  formData: {},
  headline: '',
  onBack: () => {},
  onInput: () => {},
  onNext: () => {},
  validationErrors: {},
});

export default PanelContext;
