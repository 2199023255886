import type { CmsResult, Page } from './fetch-nsp-data';

/**
 * Nsp related locale data that does not come straight from the CmsResult
 */
export type LocaleData = { fallback: string; in_city_state: string } | {};

/**
 * Allows an object to return one of its fields when rendered as a string,
 * instead of the ugly default "[Object object]".
 */
const displayable = <T>(value: T, field: keyof T) =>
  value && { ...value, toString: () => value?.[field] };

/**
 * A wrapper class for NSP context data, providing simple access to the internal
 * properties such as the lead form.
 */
export default class NspDataWrapper {
  readonly nspData: CmsResult;
  readonly subject: CmsResult['subject'];
  readonly locale: LocaleData;

  constructor(nspData: CmsResult, locale?: LocaleData) {
    this.nspData = nspData || {};
    this.subject = displayable(this.nspData.subject, 'display_name');
    this.locale = locale || {};
  }

  /** Gets the current NSP page data. */
  get page(): Page {
    return (
      this.nspData.nmePage || this.nspData.ppcPage || this.nspData.page || this.nspData.hotmathPage
    );
  }

  get body() {
    return this.page.body;
  }

  get model() {
    if (this.nspData.nmePage) return 'nme_page';
    if (this.nspData.ppcPage) return 'ppc_page';
    if (this.nspData.page) return 'page';
    if (this.nspData.hotmathPage) return 'hotmath_page';
    return null;
  }

  /** Gets the page-level lead form, if defined. */
  get leadForm() {
    return this.nspData.leadForm || this.page?.leadForm;
  }

  /** Gets the page-level dynamic navigation, if defined. */
  get navigation() {
    return this.page?.navigation || this.nspData.navigation;
  }

  get locales() {
    return this.page?._locales || ['en'];
  }

  /** Returns an object of properties that can be inserted in an interpolation. */
  get interpolationVars() {
    return {
      ...this.page,
      ...this.page?.vars,
    };
  }
}
