import { createContext, useContext } from 'react';
import NspDataWrapper from './nsp-data-wrapper';

/** A React context for an NSP object. */
const NspContext = createContext<NspDataWrapper>(new NspDataWrapper({}));
export default NspContext;

/** Allows the current component to access the page's NSP data. */
export function useNspContext(): NspDataWrapper {
  return useContext(NspContext);
}
