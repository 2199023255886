'use client';

import useFixedNavbar from '@nrdy-marketing-engine/use-fixed-navbar/src/use-fixed-navbar';
import clsx from 'clsx';

export default function CharacterLinks({ characters }: { characters: string[] }) {
  const isFixedNavbar = useFixedNavbar();

  // '\00b7' is the unicode reference for a bullet point
  return (
    <ul
      className={clsx(
        "text-sm lg:text-lg flex flex-wrap mb-2 py-2 lg:[&>li:not(:last-child)]:after:content-['\\00b7'] lg:[&>li:not(:last-child)]:after:inline -ml-2 bg-background",
        isFixedNavbar && 'sticky top-[150px]'
      )}
    >
      {characters.map((character) => (
        <li key={character}>
          <a
            className="text-link hover:underline font-bold px-2 py-1"
            href={`#concept-review-topics-${character.toLocaleLowerCase()}`}
          >
            {character}
          </a>
        </li>
      ))}
    </ul>
  );
}
